<!-- analysisSingleReport -->
<template>
  <div class="analysisSingleReport">
    <div
      class="download-btn"
      @click="printOut(reportTitle)"
      :class="isDisabled ? 'download-btn-disabled' : ''"
    >
      <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
      <img v-else :src="downloadReportImg" alt="" />
      下载报告
    </div>
    <div class="analysisSingleReport-content" id="analysisReportContent">
      <div class="page page1">
        <div class="header">
          <div class="title">
            <div>
              {{ reportTitle || '' }}
            </div>
            <div
              style="display: inline-block; margin-left: 20%; font-size: 20px"
            >
              ——{{ currentInfo.topic }}
            </div>
          </div>
          <div class="icons">
            <div class="line"></div>
            <img class="small" :src="smallStar" alt="" />
            <img class="big" :src="bigStar" alt="" />
            <img class="small" :src="smallStar" alt="" />
            <div class="line"></div>
          </div>
          <div class="dates">
            <div v-if="taskId && pushTaskInfo">
              传播日期：{{ pushTaskInfo.spreadDate }}
            </div>
            <div>数据统计日期：{{ todayDate }}</div>
          </div>
        </div>
        <div class="main-content-box">
          <div class="part">
            <h3>数据概览</h3>
            <div class="num-count-box">
              <div
                class="num-count-item"
                v-for="(item, index) in countList"
                :key="index"
              >
                <div class="num-box">
                  <div :class="['num' + index, 'num']">
                    {{ item.count || '--' }}
                  </div>
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
            <div class="first-title mb-20 mt-20">核心学者阅读情况</div>
            <commonTable
              no-serial
              :table-data="HXXZTableData"
              :column-data="HXXZColumnData"
              no-pagination
            />
            <!-- 文章统计 -->
            <!-- 文章阅读统计 -->
            <div class="part part3 mb-20 mb-20">
              <h3>文章统计</h3>
              <div class="first-title mb-20">文章阅读统计</div>
              <commonTable
                :table-data="WXYDLTableData"
                :column-data="WXYDLColumnData"
                serial-text="排名"
                no-pagination
                align-text="left"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="page page2">
        <div class="main-content-box">
          <!-- 用户分析 -->
          <div class="part part5">
            <h3>用户分析</h3>
            <div class="flex-content mt-20">
              <div class="left-content">
                <div class="first-title mb-20">阅读邮件用户来源机构</div>
                <commonTable
                  :table-data="YDYJYHLYJGTableData"
                  :column-data="YDYJYHLYJGColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
              <div class="right-content">
                <div class="first-title mb-20">阅读邮件用户来源期刊</div>
                <commonTable
                  :table-data="YDYJYHLYQKTableData"
                  :column-data="YDYJYHLYQKColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
            </div>
            <div class="flex-content mt-20">
              <div class="left-content">
                <div class="first-title mb-20">阅读邮件用户地域分布-国内</div>
                <commonTable
                  :table-data="chinaTableData"
                  :column-data="chinaColumnData"
                  no-pagination
                  align-text="left"
                />
                <p
                  class="mt-20"
                  style="font-size: 14px; text-align: center; color: #999"
                >
                  国内阅读邮件用户分布地区共{{ chinaLength }}个。
                </p>
              </div>
              <div class="right-content">
                <div class="first-title mb-20">阅读邮件用户地域分布-海外</div>
                <commonTable
                  :table-data="worldTableData"
                  :column-data="worldColumnData"
                  no-pagination
                  align-text="left"
                />
                <p
                  class="mt-20"
                  style="font-size: 14px; text-align: center; color: #999"
                >
                  海外阅读邮件用户分布地区共{{ worldLength }}个。
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="tip">北京北大方正电子有限公司提供技术支持</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import commonTable from '@comp/commonTable'
import 'echarts-wordcloud'
import { getPdf } from '@assets/js/htmlToPdf.js'
const CEApi = ProjectConfig.communicationEffectStatistics
const EMAILApi = ProjectConfig.emailPush
export default {
  name: 'AnalysisSingleReport',
  data() {
    return {
      loading: true,
      downloadReportImg: require('@assets/img/download-report.png'),
      downloadReportDisabledImg: require('@assets/img/download-report-disabled.png'),
      smallStar: require('@assets/img/star-small.png'),
      bigStar: require('@assets/img/star-big.png'),
      WXBasicInfo: {},
      pushTaskInfo: {},
      emailInfoList: [],
      isDisabled: true,
      numCountList: [],
      WXYDLTableData: [],
      WXYDLColumnData: [
        {
          title: '文献标题',
          prop: 'topic',
          special: true,
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '文献作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量',
          prop: 'clickCount',
          width: '60px',
          notSortable: true,
          align: 'center'
        }
      ],
      showCBYLL: true,
      YDYJYHLYJGTableData: [],
      YDYJYHLYJGColumnData: [
        {
          title: '机构',
          prop: 'orgName',
          notSortable: true
        },
        {
          title: '用户数',
          prop: 'count',
          notSortable: true,
          width: '60px',
          align: 'center'
        }
      ],
      YDYJYHLYQKTableData: [],
      YDYJYHLYQKColumnData: [
        {
          title: '期刊',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '用户数',
          prop: 'count',
          notSortable: true,
          width: '60px',
          align: 'center'
        }
      ],
      chinaTableData: [],
      chinaColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'percent',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      worldTableData: [],
      worldColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'percent',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      chinaLength: 0,
      worldLength: 0,
      taskId: '',
      networkId: '',
      type: '',
      year: '',
      issue: '',
      currentInfo: {},
      HXXZTableData: [],
      HXXZColumnData: [
        {
          title: '',
          prop: 'name',
          notSortable: true
        },
        {
          title: '邮件推送人数',
          prop: 'countTypeSendAuthor',
          notSortable: true
        },
        {
          title: '邮件阅读人数',
          prop: 'countTypeOpenEmailAuthor',
          notSortable: true
        },
        {
          title: '邮件阅读率',
          prop: 'typeOpenEmailRate',
          notSortable: true
        },

        {
          title: '文章阅读人数',
          prop: 'countTypeReadArticleAuthor',
          notSortable: true
        },
        {
          title: '文章阅读率',
          prop: 'typeReadArticleRate',
          notSortable: true
        }
      ]
    }
  },
  props: {},
  watch: {
    magId() {
      this.getData()
    }
  },
  components: { commonTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    todayDate() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      return year + '年' + month + '月' + day + '日'
    },
    reportTitle() {
      let text =
        '《' + this.pushTaskInfo.magName + '》' + '单篇推送数据统计报告'
      return text
    },
    countList() {
      return this.numCountList.concat(this.emailInfoList)
    }
  },
  mounted() {},
  methods: {
    getData(currentInfo, year) {
      this.currentInfo = currentInfo
      this.year = year
      this.WXBasicInfo = {}
      this.pushTaskInfo = {}
      this.emailInfoList = []
      this.numCountList = []
      this.WXYDLTableData = []
      this.YDYJYHLYJGTableData = []
      this.YDYJYHLYQKTableData = []
      this.chinaTableData = []
      this.worldTableData = []
      this.chinaLength = 0
      this.worldLength = 0
      /* this.loading = true */
      this.$emit('loadingChange', true)
      Promise.allSettled([
        // 统计报告总数统计
        this.getPushTaskReportInfo(),
        // 邮件传播、微信传播
        this.getPushTaskReportSpread(),
        // 核心学者阅读情况
        this.getHXXZTableData(),
        // 本期文献阅读量排行
        this.getArticleListData(),
        // 阅读邮件用户来源机构
        this.getYDYJYHLYJGTableData(),
        // 阅读邮件用户来源期刊
        this.getYDYJYHLYQKTableData(),
        //传播路径分析
        this.getDiffusePath(1),
        this.getDiffusePath(2)
      ]).then(() => {
        /* this.loading = false */
        this.$emit('loadingChange', false)
        this.isDisabled = false
      })
    },
    getPushTaskReportInfo() {
      this.numCountList = []
      const params = {
        taskId: this.currentInfo.id,
        // taskId: 7281,
        networkId: '',
        type: this.currentInfo.type
      }
      let url = CEApi.GetPushTaskReportInfo.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            data.rateText =
              !data.lastHistoryMagIssue || data.lastHistoryMagIssue === '01'
                ? ''
                : '（相比第' + data.lastHistoryMagIssue + '期）'
            this.pushTaskInfo = data
            this.numCountList = [
              {
                text: '送达用户',
                count: data.pushSuccessCount,
                rateNum: data.pushAuthorPercentNumber,
                rateSymbol: data.pushAuthorPercentNumberSymbol
              }
            ]
            resolve()
          }
        })
      })
    },
    getPushTaskReportSpread() {
      this.emailInfoList = []
      const params = {
        taskId: this.currentInfo.id,
        // taskId: 7281,
        networkId: ''
      }
      let url = CEApi.GetPushTaskReportSpread.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data) {
              this.emailInfoList = [
                {
                  text: '邮件阅读人次',
                  count: data.emailOPenCount,
                  rate: data.emailOpenCountPercent,
                  rateSymbol: data.emailOpenCountPercentSymbol
                },
                {
                  text: '邮件阅读人数',
                  count: data.onlyEmailOpenCount,
                  rate: data.onlyEmailOpenCountPercent,
                  rateSymbol: data.onlyEmailOpenCountPercentSymbol
                },
                {
                  text: '文章阅读人次',
                  count: data.readCount,
                  rate: data.readCountPercent,
                  rateSymbol: data.readCountPercentSymbol
                },
                {
                  text: '文章阅读人数',
                  count: data.onlyReadCount,
                  rate: data.onlyReadCountPercent,
                  rateSymbol: data.onlyReadCountPercentSymbol
                }
              ]
            }
            resolve()
          }
        })
      })
    },
    calculateOpenEmailRate(count, all) {
      if (Number(count) == 0 || Number(all) == 0) {
        return '0%'
      }
      let percentage = (count / all) * 100
      percentage = Math.round(percentage * 100) / 100
      console.log(percentage)
      if (Number.isInteger(percentage)) {
        return `${percentage}%`
      } else {
        return `${percentage.toFixed(2)}%`
      }
    },
    getHXXZTableData() {
      this.HXXZTableData = []
      const params = {
        historyId: this.currentInfo.id
        // historyId: 7281
      }
      let url = '/das-api/vas/push/task/countHistoryCorePushTypeList'
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.HXXZTableData = data
            if (data.length > 0) {
              let obj = {
                name: '合计',
                countTypeSendAuthor: 0,
                countTypeOpenEmailAuthor: 0,
                typeOpenEmailRate: 0,
                countTypeReadArticleAuthor: 0,
                typeReadArticleRate: 0
              }
              data.map((item) => {
                obj.countTypeSendAuthor =
                  obj.countTypeSendAuthor +
                  (Number(item.countTypeSendAuthor) || 0)
                obj.countTypeOpenEmailAuthor =
                  obj.countTypeOpenEmailAuthor +
                  (Number(item.countTypeOpenEmailAuthor) || 0)
                /* obj.typeOpenEmailRate =
                  obj.typeOpenEmailRate +
                  (item.typeOpenEmailRate
                    ? Number(item.typeOpenEmailRate.split('%')[0])
                    : 0)*/
                obj.typeOpenEmailRate = this.calculateOpenEmailRate(
                  obj.countTypeOpenEmailAuthor,
                  obj.countTypeSendAuthor
                )
                obj.countTypeReadArticleAuthor =
                  obj.countTypeReadArticleAuthor +
                  (Number(item.countTypeReadArticleAuthor) || 0)
                /*obj.typeReadArticleRate =
                  obj.typeReadArticleRate +
                  (item.typeReadArticleRate
                    ? Number(item.typeReadArticleRate.split('%')[0])
                    : 0)*/
                obj.typeReadArticleRate = this.calculateOpenEmailRate(
                  obj.countTypeReadArticleAuthor,
                  obj.countTypeSendAuthor
                )
              })
              // obj.typeOpenEmailRate = obj.typeOpenEmailRate + '%'
              // obj.typeReadArticleRate = obj.typeReadArticleRate + '%'
              this.HXXZTableData.push(obj)
            }
            resolve()
          }
        })
      })
    },
    // 本期文献阅读量排行
    getArticleListData(pageParams = {}) {
      this.WXYDLTableData = []
      const { page = 1, size = 15 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId,
        type: this.currentInfo.type,
        // type: 2,
        year: this.year,
        issue: '',
        topic: this.currentInfo.topic
        // issue: 'S2'
      }
      let url = CEApi.ArticleClickRankList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            const { content = [] } = data
            this.WXYDLTableData = content
            if (this.WXYDLTableData.length > 0) {
              this.WXYDLTableData.map((item) => {
                item.keywords = item.keywords.replace(/<[^>]+>/g, '')
                item.topic = item.topic.replace(/<[^>]+>/g, '')
              })
            }
          }
        }
      })
    },
    getYDYJYHLYJGTableData() {
      const params = {
        taskId: this.currentInfo.id,
        // taskId: 7281,
        page: 0,
        size: 10
      }
      let url = EMAILApi.GetOpenEmailOrg.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YDYJYHLYJGTableData = data.openEmailOrgList
            resolve()
          }
        })
      })
    },
    getYDYJYHLYQKTableData() {
      const params = {
        taskId: this.currentInfo.id,
        // taskId: 7281,
        page: 0,
        size: 10
      }
      let url = EMAILApi.GetOpenEmailMag.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YDYJYHLYQKTableData = data.openEmailMagList
            resolve()
          }
        })
      })
    },
    getDiffusePath(type) {
      const params = {
        year: '',
        magId: this.magId,
        page: 0,
        size: 10,
        type: type,
        taskId: this.currentInfo.id
        // taskId: 7281
      }
      let url = CEApi.GetDiffusePath.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data) {
              if (type == 1) {
                this.chinaLength = data.length
                this.chinaTableData =
                  data.length > 10 ? data.splice(0, 10) : data
                this.chinaTableData.map((it) => {
                  if (
                    it &&
                    it.territory &&
                    it.territory.indexOf('中国-') != -1
                  ) {
                    it.territory = it.territory.split('中国-')[1]
                  }
                })
              }
              if (type == 2) {
                this.worldLength = data.length
                this.worldTableData =
                  data.length > 10 ? data.splice(0, 10) : data
              }
            }

            resolve()
          }
        })
      })
    },
    printOut(name) {
      if (this.isDisabled) {
        return
      }
      this.$nextTick(() => {
        let pdfWidth =
          document.getElementsByClassName('analysisSingleReport-content')[0]
            .offsetWidth / 2
        let pdfHeight =
          document.getElementsByClassName('analysisSingleReport-content')[0]
            .offsetHeight / 2
        getPdf('analysisReportContent', name, pdfWidth, pdfHeight, 'a4')
      })
    }
  }
}
</script>
<style lang="scss">
.analysisSingleReport {
  .el-table--border td,
  .el-table--border th {
    border-right: 0 !important;
  }
  .el-table--border {
    border-left: none;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #fafafa;
  }
  .el-table__header-wrapper {
    .el-table__header {
      tr th {
        background: #f1f2f4;
      }
    }
  }
}
.GJCTable {
  .serial1,
  .serial2,
  .serial3 {
    width: 20px;
    height: 20px;
    background: #a6aabb;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 20px;
  }
}
.downloadIssueReport {
  .el-loading-spinner {
    top: 300px !important;
  }
}
</style>
<style lang="scss" scoped>
.analysisSingleReport {
  .download-btn {
    display: block;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    margin: 20px;
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .analysisSingleReport-content {
    width: 1135px;
    margin: auto;
    background-color: #f5f7fd;
    border: 1px solid #f5f7fd;
    .header {
      padding-top: 80px;
      padding-bottom: 20px;
      .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #4054af;
        line-height: 36px;
        text-align: center;
      }
      .icons {
        display: flex;
        align-items: center;
        margin-top: 33px;
        .line {
          width: 46%;
          height: 2px;
          border-top: 1px solid #666666;
          border-bottom: 1px solid #666666;
        }
        .small {
          margin: 0 15px;
        }
      }
      .dates {
        text-align: right;
        margin-top: 55px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        margin-right: 10px;
      }
    }
    .main-content-box {
      background-color: #fff;
      padding: 40px;
      .asc {
        color: #ec5463 !important;
      }
      .desc {
        color: #2db897 !important;
      }
      .num-count-box {
        display: flex;
        justify-content: space-between;
        .num-count-item {
          width: 190px;
          height: 120px;
          background: #f5f7fd;
          text-align: center;
          .num-box {
            height: 106px;
            display: table-cell;
            vertical-align: middle;
            width: 234px;
          }
          .num {
            font-size: 32px;
            font-family: Arial;
            font-weight: 400;
          }
          .num0 {
            color: #ec5463;
          }
          .num1 {
            color: #0ba9e1;
          }
          .num2 {
            color: #ec5463;
          }
          .num3 {
            color: #eea806;
          }
          .text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;
          }
          .rate-text {
            border-top: 1px solid rgba(166, 170, 187, 0.25);
            height: 33px;
            line-height: 33px;
            .rate {
              color: #999999;
            }
            img {
              margin: 0 4px 3px 0;
            }
          }
        }
      }
      .flex-content {
        display: flex;
        justify-content: space-between;
        .left-content,
        .right-content {
          width: 49%;
        }
      }
      .first-title {
        color: #333333;
        font-size: 18px;
        text-indent: 10px;
        line-height: 18px;
        font-weight: normal;
        &::before {
          width: 4px;
          height: 18px;
          color: #4054af;
        }
      }
      .part {
        h3 {
          font-size: 22px;
        }
      }
      .part1 {
        margin-top: 40px;
        justify-content: space-between;
        .emailPush {
          .list-item,
          .num-box {
            width: 115px;
          }
        }
        .weChatPush {
          .list-item,
          .num-box {
            width: 155px;
          }
        }
        .emailPush,
        .weChatPush {
          width: 49%;
          .num-list {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .list-item {
              height: 107px;
              background: #fafafa;
              text-align: center;
              .num-box {
                display: table-cell;
                vertical-align: middle;
                height: 76px;
                .num {
                  color: #666666;
                  font-size: 26px;
                  font-family: Arial;
                }
                .text {
                  color: #999999;
                  margin-top: 4px;
                  font-size: 14px;
                }
              }
              .rate-text {
                border-top: 1px solid rgba(166, 170, 187, 0.25);
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                img {
                  margin: 0 4px 3px 0;
                }
              }
            }
          }
        }
      }
      .part2 {
        margin-top: 50px;
        .left-content,
        .right-content {
          width: 49%;
        }
      }
      .part4 {
        .flex-content {
          background-color: #f5f7fd;
          padding: 20px;
          .GJCTable {
            width: 35%;
          }
        }
      }
      // .part3,
      // .part5,
      // .part6,
      // .part7 {
      //   margin-top: 50px;
      // }
    }
    .tip {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a6aabb;
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }
    .page {
      height: 1573.33px;
      padding: 20px;
    }
    .page2 {
      .main-content-box {
        padding: 20px 40px 40px 40px;
      }
    }
    .page3 {
      .main-content-box {
        padding: 20px 40px;
      }
      .part5 {
        h3 {
          margin-top: 10px;
        }
      }
      .mb-20 {
        margin-bottom: 15px;
      }
    }
    .page4 {
      .main-content-box {
        padding: 20px 40px;
      }
      .part6 {
        h3 {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
